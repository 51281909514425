// Imports
// ------
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Scrollbar from 'smooth-scrollbar';
import StopScrollPlugin from '@utils/stopSS';
import { experienceModal } from '@states';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { observer } from 'mobx-react-lite';

// Styles
// ------
import { Jacket } from './styles';

// Component
// ------
Scrollbar.use(StopScrollPlugin);

const SmoothScroll = ({ children }) => {
	// NOTE • Refs
	const scroller = useRef();

	// NOTE • OnLoad
	useEffect(() => {
		const bodyScrollBar = Scrollbar.init(scroller.current, {
			damping: 0.3,
			thumbMinSize: 20,
			renderByPixels: true,
			// alwaysShowTracks: false,
			continuousScrolling: false,
		});

		if(experienceModal.isActive) {
			bodyScrollBar.updatePluginOptions("stopScroll", { scroll: false })
		} else {
			bodyScrollBar.addListener(ScrollTrigger.update);
			bodyScrollBar.updatePluginOptions("stopScroll", { scroll: true })
		}
	}, [experienceModal.isActive]);

	return (
		<Jacket ref={scroller} id="scroller">
			{children}
		</Jacket>
	);
};

// Prop Types
// -------
SmoothScroll.propTypes = {
	children: PropTypes.node.isRequired,
};

// Exports
// ------
export default observer(SmoothScroll);
